<template>
  <div class="lg:p-16 p-6">
    <div class="bg-white">
      <div v-if="course" class="max-w-2xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8 lg:grid lg:grid-cols-2 lg:gap-x-8">
        <!-- Product details -->
        <div class="lg:max-w-lg lg:self-end">
          <nav aria-label="Breadcrumb">
            <ol role="list" class="flex items-center space-x-2">
            </ol>
          </nav>

          <img v-if="course.image" :src="course.image" class="w-full rounded-2xl" style="aspect-ratio: 3/2" />

          <div class="mt-4">
            <h1 class="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">{{ course.product.name }}</h1>
          </div>

          <section aria-labelledby="information-heading" class="mt-4">
            <h2 id="information-heading" class="sr-only">Product information</h2>

            <div class="flex items-center">
              <p class="text-lg text-gray-900 sm:text-xl">{{ course['product'].prices.find(e=>e.active)?.unit_amount / 100 }} EUR</p>
            </div>

            <div class="mt-4 space-y-6">
              <div v-html="course.description" class="text-base text-gray-500"></div>
            </div>

            <div class="mt-6 flex items-center">
              <InformationCircleIcon class="flex-shrink-0 w-5 h-5 text-green-500" aria-hidden="true" />
              <p class="ml-2 text-sm text-gray-500">{{course.additional_info}}</p>
            </div>
          </section>
        </div>
        <div class="mt-10 lg:max-w-lg lg:col-start-1 lg:row-start-2 lg:self-start">
          <section aria-labelledby="options-heading">
            <h2 id="options-heading" class="sr-only">Product options</h2>
          </section>
          <button
              v-if="user && !userCoursesId.includes(course.pk)"
              @click.prevent="cartItemToggle(course)"
              class="w-full bg-blue-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 ">
            {{isInCart(course) ? 'Remove from cart' : 'Add to cart'}}
          </button>
          <button
              v-else-if="user"
              @click.prevent="$router.push('/course/start/' + course.pk)"
              class="mt-8 block w-full bg-green-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-green-800"
          >
            Start
          </button>
        </div>
      </div>
    </div>
    <div class="bg-white">
      <div class="flex">
        <div v-if="course" class="flex flex-col md:flex-row items-center justify-center whitespace-nowrap py-3 px-4 sm:px-6 lg:space-x-24 lg:px-8 mx-auto">
          <div class="flex items-center text-sm font-medium text-blue-600">
            <clock-icon class="mr-2 flex-none w-6 h-6" aria-hidden="true" />
            <p>Lessons - {{Math.floor(course.seconds_per_page * course.pages_count / 60)}} minutes {{course.seconds_per_page * course.pages_count - Math.floor(course.seconds_per_page * course.pages_count / 60) * 60}} seconds</p>
          </div>
          <div class="flex items-center text-sm font-medium text-blue-600">
            <badge-check-icon class="mr-2 flex-none w-6 h-6" aria-hidden="true" />
            <p>Test - {{course.questions_number}} questions for {{Math.floor(course.seconds_per_question * course.questions_number / 60)}} minutes {{course.seconds_per_question * course.questions_number - Math.floor(course.seconds_per_question * course.questions_number / 60) * 60}} seconds</p>
          </div>
          <div class="flex items-center text-sm font-medium text-blue-600">
            <calendar-icon class="mr-2 flex-none w-6 h-6" aria-hidden="true" />
            <p>{{course.validity_period_months}} months - Validity of the license</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { CheckIcon, QuestionMarkCircleIcon, StarIcon, InformationCircleIcon } from '@heroicons/vue/solid'
import { RadioGroup, RadioGroupDescription, RadioGroupLabel, RadioGroupOption } from '@headlessui/vue'
import { BadgeCheckIcon, CalendarIcon, TruckIcon, ClockIcon } from '@heroicons/vue/outline'

import { ShieldCheckIcon } from '@heroicons/vue/outline'
import {mapGetters, mapMutations} from "vuex";

export default {
  components: {
    RadioGroup,
    RadioGroupDescription,
    RadioGroupLabel,
    RadioGroupOption,
    CheckIcon,
    QuestionMarkCircleIcon,
    ShieldCheckIcon,
    StarIcon,
    InformationCircleIcon,
    CalendarIcon,
    BadgeCheckIcon,
    ClockIcon
  },
  data() {
    return {
      course: null,
      incentives: [
        { name: 'Free, contactless delivery', icon: TruckIcon },
        { name: 'No questions asked returns', icon: BadgeCheckIcon },
        { name: '2-year warranty', icon: CalendarIcon },
      ]
    }
  },
  methods: {
    ...mapMutations('cart', ['cartItemToggle']),
    isInCart(course) {
      return this.cart.findIndex(e => e.pk == course.pk) != -1
    }
  },
  computed: {
    ...mapGetters('cart', ['cart']),
    ...mapGetters('auth', ['userCoursesId', 'user'])
  },
  async mounted() {
    let course = await this.axios.get(`service/course/${this.$route.params.id}/`)
    this.course = course.data
  }
}
</script>